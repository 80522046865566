










































































































































import { Vue, Component, Mixins } from 'vue-property-decorator'

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'

import MyBox from '@/components/box.vue'

import {
  use,
  registerMap,
  registerTheme,
  connect,
  disconnect,
} from 'echarts/core'
import {
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
} from 'echarts/charts'
import {
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components'

import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

import VChart, { THEME_KEY } from 'vue-echarts'

use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
])

@Component({
  components: {
    MyDvBox,
    MyBox,
    VChart,
  },
  provide: {
    [THEME_KEY]: 'light',
  },
})
export default class extends Mixins() {
  option1: any = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params: any) => {
        let tar: any = params[0]
        return tar.name + '<br />' + tar.seriesName + ':' + tar.value
      },
    },
    grid: {
      borderWidth: 0,
    },
    xAxis: [
      {
        type: 'category',

        data: ['总收入', '渠道A', '渠道B', '渠道C', '渠道D', '渠道E'],
        axisLabel: {
          formatter: '{value} ',
          textStyle: {
            color: '#a4a7ab',
            align: 'right',
          },
        },
        splitLine: { show: false },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: '{value} ',
          textStyle: {
            color: '#a4a7ab',
            align: 'right',
          },
        },
        splitLine: { show: false },
      },
    ],
    series: [
      {
        name: '总收入',
        type: 'bar',
        stack: '总量',
        itemStyle: {
          normal: {
            barBorderColor: 'rgba(0,0,0,0)',
            color: 'rgba(0,0,0,0)',
          },
          emphasis: {
            barBorderColor: 'rgba(0,0,0,0)',
            color: 'rgba(0,0,0,0)',
          },
        },
        data: [0, 1700, 1400, 1200, 300, 0],
      },
      {
        name: '渠道',
        type: 'bar',
        stack: '总量',
        data: [2900, 1200, 300, 200, 900, 300],
        itemStyle: {
          normal: {
            color: '#2481ff',
          },
        },
      },
    ],
  }

  option2: any = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
      },
      formatter: (params: any) => {
        var tar = params[0]
        return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value
      },
    },

    xAxis: [
      {
        type: 'category',
        splitLine: { show: false },
        data: ['总收入', '渠道A', '渠道B', '渠道C', '渠道D', '渠道E'],
        axisLabel: {
          formatter: '{value} ',
          textStyle: {
            color: '#a4a7ab',
            align: 'right',
          },
        },
      },
    ],
    grid: {
      borderWidth: 0,
    },
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: '{value} ',
          textStyle: {
            color: '#a4a7ab',
            align: 'right',
          },
        },
        splitLine: { show: false },
      },
    ],
    series: [
      {
        name: '总收入',
        type: 'bar',
        stack: '总量',
        itemStyle: {
          normal: {
            color: '#1afffd',
          },
        },
        data: [0, 1700, 1400, 1200, 300, 0],
      },
      {
        name: '渠道',
        type: 'bar',
        stack: '总量',
        itemStyle: {
          normal: {
            color: '#28a3e1',
          },
        },
        data: [2900, 1200, 300, 200, 900, 300],
      },
    ],
  }

  option3: any = {
    title: {
      text: '销售量组成',
      textStyle: {
        color: '#e9ebee',
      },

      x: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      x: 'left',
      data: ['销售A', '销售B', '销售C', '销售D', '销售E'],
      textStyle: {
        color: '#e9ebee',
      },
    },

    calculable: false,
    series: [
      {
        name: '销售组成',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [
          {
            value: 335,
            name: '销售A',
            itemStyle: {
              normal: {
                color: '#1afffd',
              },
            },
          },
          {
            value: 310,
            name: '销售B',
            itemStyle: {
              normal: {
                color: '#2e7cff',
              },
            },
          },
          {
            value: 234,
            name: '销售C',
            itemStyle: {
              normal: {
                color: '#ffcb89',
              },
            },
          },
          {
            value: 135,
            name: '销售D',
            itemStyle: {
              normal: {
                color: '#005ea1',
              },
            },
          },
          {
            value: 1548,
            name: '销售E',
            itemStyle: {
              normal: {
                color: '#0ad5ff',
              },
            },
          },
        ],
      },
    ],
  }
  option4: any = {
    title: {
      text: '营销投入组成',
      textStyle: {
        color: '#e9ebee',
      },

      x: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      x: 'left',
      data: [
        '投入A',
        '投入B',
        '投入C',
        '投入D',
        '投入E',
        '投入F',
        '投入G',
        '投入H',
        '投入J',
      ],
      textStyle: {
        color: '#e9ebee',
      },
    },

    calculable: false,
    series: [
      {
        name: '销售组成',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [
          {
            value: 335,
            name: '投入A',
            itemStyle: {
              normal: {
                color: '#06b8f8',
              },
            },
          },
          {
            value: 310,
            name: '投入B',
            itemStyle: {
              normal: {
                color: '#ff5c58',
              },
            },
          },
          {
            value: 234,
            name: '投入C',
            itemStyle: {
              normal: {
                color: '#ffffb3',
              },
            },
          },
          {
            value: 135,
            name: '投入D',
            itemStyle: {
              normal: {
                color: '#fee581',
              },
            },
          },
          {
            value: 1548,
            name: '投入E',
            itemStyle: {
              normal: {
                color: '#1afffd',
              },
            },
          },
          {
            value: 135,
            name: '投入F',
            itemStyle: {
              normal: {
                color: '#1ec7f1',
              },
            },
          },
          {
            value: 135,
            name: '投入G',
            itemStyle: {
              normal: {
                color: '#e15828',
              },
            },
          },
          {
            value: 135,
            name: '投入H',
            itemStyle: {
              normal: {
                color: '#28a3e1',
              },
            },
          },
          {
            value: 135,
            name: '投入J',
            itemStyle: {
              normal: {
                color: '#72e7d5',
              },
            },
          },
        ],
      },
    ],
  }
  option5: any = {
    title: {
      text: '销售量与投入量分析',
      textStyle: {
        color: '#e9ebee',
      },
      x: 'center',
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      orient: 'vertical',
      x: 'left',
      data: ['营销投入量', '销售量'],
      textStyle: {
        color: '#e9ebee',
      },
    },

    calculable: false,
    xAxis: [
      {
        type: 'category',
        splitLine: { show: false },
        axisLabel: {
          formatter: '{value} ',
          textStyle: {
            color: '#a4a7ab',
            align: 'right',
          },
        },
        data: [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月',
        ],
      },
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: { show: false },
        axisLabel: {
          formatter: '{value} ',
          textStyle: {
            color: '#a4a7ab',
            align: 'right',
          },
        },
      },
    ],
    grid: {
      borderWidth: 0,
    },
    series: [
      {
        name: '营销投入量',
        type: 'bar',
        data: [
          2.0,
          4.9,
          7.0,
          23.2,
          25.6,
          76.7,
          135.6,
          162.2,
          32.6,
          20.0,
          6.4,
          3.3,
        ],
        itemStyle: {
          normal: {
            color: '#2481ff',
          },
        },
        markPoint: {
          data: [
            { type: 'max', name: '最大值' },
            { type: 'min', name: '最小值' },
          ],
        },
        markLine: {
          data: [{ type: 'average', name: '平均值' }],
        },
      },
      {
        name: '销售量',
        type: 'bar',
        data: [
          2.6,
          5.9,
          9.0,
          26.4,
          28.7,
          70.7,
          175.6,
          182.2,
          48.7,
          18.8,
          6.0,
          2.3,
        ],
        itemStyle: {
          normal: {
            color: '#1afffd',
          },
        },

        markPoint: {
          data: [
            {
              name: '最高',
              value: 182.2,
              xAxis: 7,
              yAxis: 183,
              symbolSize: 18,
            },
            { name: '最低', value: 2.3, xAxis: 11, yAxis: 3 },
          ],
        },
        markLine: {
          data: [{ type: 'average', name: '平均值' }],
        },
      },
    ],
  }

  ei1: any = {}
  ei2: any = {}
  ei3: any = {}
  ei4: any = {}
  ei5: any = {}

  mounted() {
    // this.ei.setOption(this.option)
    this.$nextTick(() => {
      // this.ei1.resize()
      // this.ei2.resize()
      // this.ei3.resize()
      // this.ei4.resize()
      // this.ei5.resize()
    })
  }
}
